<template>
	<div>
		<!-- Title and Logo -->
		<h-retribusi />
		<!-- end Logo and Title -->
		<panel title="LIST PERANGKAT" class="panel panel-success">
			<!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> LIST PERANGKAT</h6>
            </template> -->
			<div class="pl-4 py-2 border shadow-sm">
				<!-- <span v-if="roleArr.includes(role) === true"><b-button variant="primary" to="/file-master/pemerintah-daerah/perangkat/tambah"><i class="fa fa-plus pr-1"></i> Tambah KODE PERANGKAT</b-button></span> -->
				<span class="px-2">
					<b-button variant="primary" @click="reload"><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button>
				</span>
			</div>
			<div class="py-3">
				<b-card class="shadow-sm rounded-0">
					<!-- <vue-good-table 
						:columns="columns" 
						:rows="rows" 
						:lineNumbers="true"
						:search-options="{ enabled: true, placeholder: 'Search this table' }" 
						:pagination-options="{
							enabled: true,
							mode: 'records',
							perPage: 10,
							position: 'bottom',
							perPageDropdown: [15, 20, 25],
							dropdownAllowAll: true,
							setCurrentPage: 1,
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}"
					>
						<template slot="table-column" slot-scope="props">
							<span style="white-space: pre;">{{ props.column.label}}</span>
						</template>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === 'action' ">
								<b-dropdown split class="m-r-2" size="sm" variant="info">
									<template #button-content>
										<i class="fa fa-cogs pr-1"></i> Aksi
									</template>
									<b-dropdown-item><i class="fa fa-edit"></i> Edit</b-dropdown-item>
									<b-dropdown-item @click="swalNotification('error')"><i class="fa fa-trash-alt"></i> Hapus</b-dropdown-item>
								</b-dropdown>
							</span>
						</template>
					</vue-good-table> -->
					<!-- Tools & Search-->

					<!-- filter section -->
					<b-row>
						<b-col md="6" class="mb-3">
							<b-form-group :label-cols="3" label="Filter By" class="mb-0 my-1">
								<b-input-group size="md">
									<b-form-select v-model="filterBy" :options="fieldOptions"
										@change="changeFilterBy()">
										<option slot="first" :value="'all'">All Data</option>
									</b-form-select>
								</b-input-group>
							</b-form-group>
							<b-form-group :label-cols="3" label="Filter" class="mb-0"
								description="Type to Search or Click Clear to Stop Searching ">
								<b-input-group size="md">
									<b-form-input v-model="filter" @keyup.enter="testFunc(filter)"
										placeholder="Type to Search" debounce="500" />
									<b-input-group-append>
										<b-btn :disabled="!filter" @click="filter = ''" variant="info"><i
												class="fa fa-sync"></i> Clear</b-btn>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md="6" class="mt-1">
							<b-form-group :label-cols="3" label="Sort" class="mb-0 my-1">
								<b-input-group size="md">
									<b-form-select v-model="sortBy" :options="fieldOptions">
										<option slot="first" :value="null">-- none --</option>
									</b-form-select>
									<b-form-select :disabled="!sortBy" v-model="sortDesc" slot="append">
										<option :value="false">Asc</option>
										<option :value="true">Desc</option>
									</b-form-select>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<!-- end filter section -->
					<!-- end Tools & Search -->

					<!-- Data Table & Pagination -->
					<b-table show-empty small id="masTable" ref="masTable" head-variant="light" :hover="true"
						:busy.sync="isBusy" :items="myGetData" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="testFilter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						no-provider-filtering responsive bordered>

						<template #cell(index)="data">
							{{ data.index + 1 }}
						</template>

						<template v-slot:table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</template>

						<template v-slot:table-colgroup="scope">
							<col v-for="field in scope.fields" :key="field.key"
								:style="{ width: field.key === 'actions' ? '105px' : '' }" />
						</template>

						<!-- klasifikasi pd -->
						<!-- <template v-slot:cell(urusan_pd)="data">
						{{ data.item.klasifikasi_pd.urusan_pd.nama }}
					</template> -->
						<!-- end klasifikasi pd --->

						<!-- klasifikasi pd -->
						<!-- <template v-slot:cell(klasifikasi_pd)="data">
						{{ data.item.klasifikasi_pd.nama }}
					</template> -->
						<!-- end klasifikasi pd --->

						<!-- button actions -->
						<!-- <template v-slot:cell(actions)="data" v-if="roleArr.includes(role) === true">
						<b-dropdown 
							split 
							class="d-flex align-item-center" 
							size="sm" 
							variant="info">
							<template #button-content>
								<i class="fa fa-cogs pr-1"></i> Aksi
							</template>
							<b-dropdown-item @click="editButton(data.item.id)"><i class="fa fa-edit"></i> Edit</b-dropdown-item>
							<b-dropdown-item @click="swalNotification('error', data.item.id)"><i class="fa fa-trash-alt"></i> Hapus</b-dropdown-item>
						</b-dropdown>
					</template>
					<template v-slot:cell(actions) v-else>
						<div class="text-center">
							<span class="badge badge-danger" v-b-tooltip.hover.left title="Anda tidak memiliki akses untuk menggunakan fitur ini.">Unauthorized.</span>
						</div>
					</template> -->
						<!-- end button actions -->

					</b-table>
					<div class="divider"></div>

					<!-- pagination section -->
					<b-row>
						<div class="divider"></div>
						<b-col md="6" class="my-0">
							<b-form-group :label-cols="2" label="Per page" class="mb-0">
								<b-form inline>
									<b-form-select :options="pageOptions" style="width: 100px;" v-model="perPage" />
									<label class="ml-1 mr-1">/</label>
									<b-form-input :disabled="true" v-model="totalRows" style="width: 100px;"
										class="text-right" />
									<label class="ml-1">Rows</label>
								</b-form>
							</b-form-group>
						</b-col>
						<b-col md="6" class="my-0">
							<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
								size="md" class="my-0 "></b-pagination>
						</b-col>
					</b-row>
					<!-- end pagination section -->

					<!-- end Data Table & Pagination -->
				</b-card>
			</div>
		</panel>
	</div>
</template>

<script>
	/* eslint-disable */
	// eslint-disable-next-line
	// eslint-disable vue/no-unused-vars
	// eslint-disable-line no-unused-vars

	import Logo from '@/assets/images/logoSumut.png'
	import axios from 'axios'
	import {
		mapState
	} from 'vuex'
	import HRetribusi from '@/components/header/HRetribusi.vue'
	import filterMixin from '@/helper/filterMixin.js'

	export default {
		mixins: [filterMixin],
		components: {
			HRetribusi
		},
		data() {
			return {
				imageLogo: Logo,
				// Tools
				isBusy: false,
				sortBy: null,
				sortDesc: false,
				filter: null,
				testFilter: '',
				filterBy: "all",
				pageOptions: [5, 10, 15, 25],
				perPage: 10,
				currentPage: 1,
				totalRows: 0,
				roleArr: ['["Maintenance"]', '["Administrator"]', '["Pimpinan"]'],
				role: localStorage.getItem('roles'),
				/*  columns: [{
				label: 'KODE',
				field: 'kode',
				tdClass: 'text-nowrap',
				thClass: 'text-nowrap'
			}, {
				label: 'NAMA URUSAN',
				field: 'nama',
				thClass: 'text-nowrap',
				tdClass: 'text-nowrap'
			}, {
				label: 'Opsi',
				field: 'action',
				type: 'percentage',
				width: '10%',
				tdClass: 'text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap',
				thClass: 'text-center text-nowrap'
			}],
      rows: [
        { id:1, kode: '1', nama:'Urusan Wajib'},
        { id:2, kode: '1', nama:'Urusan Wajib'},
        { id:3, kode: '1', nama:'Urusan Wajib'},
        { id:4, kode: '1', nama:'Urusan Wajib'},
	  ], */
				fields: [{
						key: "index",
						label: 'No.'
					},
					{
						key: "kode",
						label: 'Kode',
						sortable: true
					},
					{
						key: "nama",
						label: 'Perangkat',
						sortable: true
					},
					//   {
					// 	  key: "urusan_pd",
					// 	  label: 'Urusan',
					// 	  sortable: true
					//   },
					//   {
					// 	  key: "klasifikasi_pd",
					// 	  label: 'Klasifikasi',
					// 	  sortable: true
					//   },
					//   {
					// 	  key: "actions",
					// 	  label: 'Opsi'
					//   }
				]
			}
		},
		computed: {
			fieldOptions() {
				// Create an options list from our fields
				return this.fields
					.filter((f) => f.sortable)
					.map((f) => {
						return {
							text: f.label,
							value: f.key,
						};
					});
			},
			/* ...mapState([
				'role'
			]) */
		},
		methods: {
			myGetData(ctx) {
				// ctx =
				// berasal dari tag <b-table></b-table>
				//this.$Progress.start();
				this.isBusy = true;
				let promise = axios.get("/api/pemerintah-daerah/perangkat-pd", {
					params: {
						page: ctx.currentPage,
						perpage: ctx.perPage,
						sortby: ctx.sortBy,
						sortdesc: ctx.sortDesc,
						filter: ctx.filter,
						filterby: this.filterBy,
						klasifikasipd: true,
						urusanpd: true
					},
				});
				return promise
					.then((response) => {
						const items = response.data.data;
						// Data Pagination
						// configPagination(response.data.meta);
						this.totalRows = response.data.meta.total;
						// Here we could override the busy state, setting isBusy to false
						//this.$Progress.finish();
						this.isBusy = false;
						return items;
					})
					.catch((error) => {
						// Here we could override the busy state, setting isBusy to false
						//this.$Progress.fail();
						this.isBusy = false;
						if (error.response.status === 401) {
							localStorage.removeItem('ER_token')
							this.$router.push({
								name: "Login"
							})
						}
						// Returning an empty array, allows table to correctly handle
						// internal busy state in case of error
						return [];
					});
			},
			reload() {
				// with id="masTable" in tag b-table
				// this.$root.$emit('bv::refresh::table', 'masTable')
				// with property ref="masTable" in tag b-table
				this.$refs.masTable.refresh();
			},
			changeFilterBy() {
				if (this.filter) {
					this.$refs.masTable.refresh();
				}
			},
			swalNotification(swalType, id) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
				btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Anda yakin ?',
					text: 'Anda tidak akan bisa mengembalikan data!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: "Hapus",
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn m-r-5 btn-' + btnClass + '',
					cancelButtonClass: 'btn btn-default',
				}).then(result => {
					if (result.isConfirmed) {
						axios.delete('/api/pemerintah-daerah/perangkat-pd/' + id).then(response => {
							this.$swal({
								title: "Data Berhasil Dihapus!",
								icon: 'success',
								type: 'success',
								showCloseButton: true,
								showConfirmButton: false,
								timer: 1800
							})
							this.reload()
						}).catch(error => {
							if (error.response.status === 401) {
								localStorage.removeItem('ER_token')
								this.$router.push({
									name: "Login"
								})
							}
						})
					}
				})
			},
			editButton(index) {
				this.$router.push({
					name: 'EditPerangkat',
					params: {
						id: index
					}
				})
			}
		}
	}
</script>

<style scoped>

</style>