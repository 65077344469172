export const filterMixin = {
    methods: {
        testFunc (word) {
            this.testFilter = word
        },
        clearFilter () {
            this.filter = ''
            this.testFilter = ''
        }
    }
}

export default filterMixin